/* get global page data on start */
import { useMainStore } from "~/stores/MainStore";
export default defineNuxtPlugin(async () => {
  const mainStore = useMainStore()
  const route = useRoute()
  await mainStore.fetchSiteContent()
  await mainStore.fetchBlogContent()
  // set preview mode active if query contains "preview"
  if (route.query.preview) {
    mainStore.previewIsActive = true    
  }
})
