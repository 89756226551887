<script setup lang="ts">
import type { NuxtError } from '#app'

const props = defineProps({
  error: Object as () => NuxtError
})
const error = useError()
const handleError = () => {
  clearError({
    redirect: '/',
  })
}
</script>
<template>
  <NuxtLayout>
    <div class="max-w-md mx-auto py-24 text-center">
      <h1 class="text-7xl font-semibold mb-6 leading-[1.5] text-burgundy-600">
        {{ props.error?.statusCode }}
      </h1>
      <p> Å, nei! Beklager, her har det skjedd en feil. </p>
      <p class="rounded bg-burgundy-600 text-base text-white my-4 p-2">
        {{ props.error?.message }}
      </p>
      <div class="py-8 flex justify-center">
        <UButton color="info" variant="link" @click="handleError">
          Gå tilbake til forsiden
        </UButton>
      </div>
    </div>
  </NuxtLayout>
</template>


