import { siteSettingsQuery, pageBuilderQuery } from "./helperQueries"

export const siteQuery = groq`{
  ${siteSettingsQuery}
}`

export const frontpageQuery = groq`
*[_type == 'frontpage'] | order(_updatedAt desc) [0]{
	...,
	testimonialQuery,
	pageBuilder[] {
		...,
    ${pageBuilderQuery}
	}
}`

export const pageQuery = groq`
*[_type == 'page' && slug.current == $slug] | order(_updatedAt desc)[0]{
	...,
	testimonialQuery,
	pageBuilder[] {
		...,
    ${pageBuilderQuery}
	}
}
`

export const postQuery = groq`
*[_type == 'post' && slug.current == $slug] | order(orderRank)[0]{
	...,
  author->,
	testimonialQuery,
	pageBuilder[] {
		...,
    ${pageBuilderQuery}
	}
}
`

export const blogPostsQuery = groq`*[_type == "post"] | order(orderRank) {
  ...,
	title,
  description,
  slug,
  mainImage,
  publishedAt,
  category {
    "title": *[_id == ^._ref][0].title
  },
	testimonialQuery,
	pageBuilder[] {
		...,
    ${pageBuilderQuery}
	}
}`


// bruk spørring for å vise i csv
export const seoJsonQuery = groq`
*[_type in ["page", "frontpage", "post"] ] | order(_type asc, _updatedAt desc) 
{ 
  (_type == "post") => {
    "slug": "/blog/" + slug.current
  },
  (_type == "page" || _type == "frontpage") => {
    "slug": "/" + coalesce(slug.current,'')
  },
  "seo_title":seo.title,
  "seo_description":seo.description  
}`
