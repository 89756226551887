import { defineStore } from "pinia"
import { siteQuery, blogPostsQuery } from "~/queries/contentQueries"
import type { Testimonial, SiteNav, SiteOwner, SiteSettings, SiteSetup, CTA } from "~~/types"

export const useMainStore = defineStore("MainStore", () => {
  const slugs = ref<string[]>()
  const siteNav = ref<SiteNav>()
  const siteOwner = ref<SiteOwner>()
  const siteSettings = ref<SiteSettings>()
  const cta = ref<CTA>()
  const testimonials = ref<Testimonial[]>()
  const randomNumber = ref(0)
  const blogPosts = ref<any>()
  const previewIsActive = ref(false)

  const fetchSiteContent = async () => {
    const { data } = await useSanityData<SiteSetup>({ query: siteQuery })
    siteNav.value = data.value?.siteNav
    slugs.value = data.value?.slugs.pages
    siteSettings.value = data.value?.siteSettings
    siteOwner.value = data.value?.siteOwner
    cta.value = data.value?.cta
    testimonials.value = data.value?.testimonials
  }

  const fetchBlogContent = async () => {  
    const { data } = await useSanityData<SiteSetup>({ query: blogPostsQuery })
    blogPosts.value = data.value
  }

  const services = computed(() => siteNav.value?.header[0].items.map((item: any) => {
    return { 
      title: item.meta.title,
      slug: item.meta.slug,
      description: item.meta.description,
      mainImage: item.meta.mainImage
    }
  }))

  const randomTestimonial = computed(() => {
    return testimonials.value ? testimonials.value[randomNumber.value] : undefined
  })
  const randomizeTestimonial = () => {
    const random = testimonials.value ? Math.floor(Math.random() * testimonials.value.length) : 0
    randomNumber.value = random
  }

  const filterBlogPosts = (limit: number | string, category?: any) => {
    if (limit === "all") return blogPosts.value
    return blogPosts.value.slice(0, limit)
  }

  const getBlogPost = (slug: string) => {
    return blogPosts.value.find((post: any) => post.slug.current === slug)
  }

  return {
    siteNav,
    siteSettings,
    siteOwner,
    slugs,
    cta,
    previewIsActive,
    blogPosts,
    testimonials,
    fetchSiteContent,
    fetchBlogContent,
    filterBlogPosts,
    getBlogPost,
    randomTestimonial,
    randomizeTestimonial,
    services
  }
})
