<script setup lang="ts">
const error = useError()
const resetError = () => {
  error.value = undefined
}
const { initialize } = useHotjar()
onMounted(() => {
  initialize()
})
</script>
<template>
  <UApp>
    <div class="bg-[var(--ui-bg)]" vaul-drawer-wrapper>
      <NuxtErrorBoundary>
      <NuxtPage />
      <template #error="{ error }">
        Client error occured: {{ error }}
        <div>
          <button class="" @click="resetError">
            Reset
          </button>
        </div>
      </template>
    </NuxtErrorBoundary>
    </div>
  </UApp>
</template>

<style>
:root {
  --header-height: 4rem;
}

.dark {
  background-color: var(--color-gray-400);
  --ui-bg: var(--ui-color-neutral-600);
}

.sanity-blocks p {
  white-space: break-spaces;
}

.sanity-blocks ul {
  list-style-type: disc;
  list-style-position: inside;
  margin-bottom: 16px;
}

.sanity-blocks ol {
  list-style-type: decimal;
  list-style-position: inside;
}

.sanity-blocks ul ul,
.sanity-blocks ol ul {
  list-style-type: circle;
  list-style-position: inside;
  /* margin-left: 15px; */
}

.sanity-blocks ol ol,
.sanity-blocks ul ol {
  list-style-type: lower-latin;
  list-style-position: inside;
  /* margin-left: 15px; */
}

.sanity-blocks li {
  padding-top: 6px;
  padding-bottom: 6px;
}
</style>