export default defineAppConfig({
  ui: {
    colors: {
      primary: "burgundy",
      neutral: "zinc"
    }
  },
  theme: {
    radius: 0.25
  },
  icons: {
    dark: "i-heroicons-moon",
    light: "i-heroicons-sun"
  }
})
