
import * as sanityRuntime$SvEcHRzp1R from '/opt/build/repo/www/node_modules/.pnpm/@nuxt+image@1.9.0_db0@0.2.4_ioredis@5.5.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/sanity'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 868,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "sanity",
  "domains": [
    "sanity.io",
    "netlify.app",
    "fotostorie.no",
    "localhost"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['sanity']: { provider: sanityRuntime$SvEcHRzp1R, defaults: {"projectId":"x9bmhgsh","dataset":"production"} }
}
        