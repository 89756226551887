export const seoQuery = `
	seo {
		...,
	}
`
export const siteSettingsQuery = `
"siteNav": *[_id == "siteNavigation"] [0] {
  header[] {
    title,
    items[] {
      "meta": *[_id == ^._ref][0] {
        "slug": slug.current,
        title,
        description,
        mainImage,
      }
    },
    "meta": *[_id == ^._ref][0] {
        "slug": slug.current,
        title
      }
  },
  footer[]{
      title,
      items[] {
        "meta": *[_id == ^._ref][0] {
          "slug": slug.current,
          title,
          description,
          mainImage,
        }
      },
      "meta": *[_id == ^._ref][0] {
        "slug": slug.current,
        title
      }
    }
  },
  "slugs": {
    "pages": *[_type == "page"].slug.current,
    "posts": *[_type == "post"].slug.current,
  },
  "cta": *[_type == "siteCTA"][0].cta {
    caption,
    title,
    buttons[] {
      display,
      label,
      internalLink {
        ...,
        "meta": *[_id == ^._ref][0] {
          "slug": slug.current,
          title,
          description,
        }
      }
    }
  },
  "siteOwner": *[_type == "siteOwner"][0] {
    email,
    footer,
    orgno,
    name,
    tagline,
    social
  },
  "siteSettings": *[_type == "siteSettings"][0] {
    leadingHeaderTitle,
    headerTitle,
    subHeaderTitle,
    vatIncluded,
    ${seoQuery}
  },
  "testimonials": *[_type == "testimonial"] {
    name,
    photo,
    quote,
  },
`

export const linkQuery = `
	_type == "internalLink" => {
		"linkType": "internalLink",
		"title": coalesce( title,
			linkTarget->title
		),
		"route": select(
			linkTarget->_type == "frontpage" => "index",
			linkTarget->_type == "post" => "post",
			linkTarget->_type == "page" => "slug",
		),
		"slug": linkTarget->slug.current
	},
	_type == "link" => {
		...,
		"linkType": "externalLink",
		"title": coalesce(title, href)
	}
`

export const contentBlockQuery = `
	_type == "block" => {
		...
	},
	_type == "video" || _type == "videoPlayer" => {
		_type,
		"video": {muxVideo{..., asset->}},
		posterImage {..., asset->}
	},
	markDefs[]{
		...,
		_type == "download" => {"url": asset->url},
		${linkQuery}
	}
`

///

export const buttonQuery = `_type == "button" => {
  ...,
  internalLink {
    ...,
    "slug": @.item->slug.current,
    "meta": *[_id == ^._ref][0] {
      "slug": slug.current,
      "articleType": _type,
      title,
      description,
      imageAsset {..., asset->},
      mainImage {..., asset->},
    }
  }
}`

export const testimonialQuery = `
  "testimonials": *[_type=='testimonial' && references(^._id)]{ 
    name,
    quote,
    photo
  }
`

export const pageBuilderQuery = `
  references[] {
    ...,
    "contentType": "links",
    "meta": *[_id == ^._ref][0] {
      "slug": slug.current,
      "articleType": _type,
      title,
      description,
      mainImage,
    }
  },
  columns[] {
    ...,
    "contentType": "columns",
    items[] {
      ...,
      body[] {
        ...,
        markDefs[] {
          ...,
          _type == "internalLink" => {
            "slug": @.item->slug.current,
          },
        },
        ${buttonQuery}
      }
    }
  },
  questions[] {
    ...,
    "contentType": "faq",
    answer[] {
      ...,
      markDefs[] {
        ...,
        _type == "internalLink" => {
          "slug": @.item->slug.current,
        },
      }
    }
  },
  body[] {
    ...,
    "contentType": "content",
    markDefs[] {
      ...,
      _type == "internalLink" => {
        "slug": @.item->slug.current,
        "type": @.item->_type
      },
    }
  },
  products[] {
    "contentType": "products",
    "product": *[_id == ^._ref ][0] {
      amount,
      discount,
      included,
      terms,
      title
    },
  },
  _type == "pageBuilderTestimonial" => {
    _type,
    "testimonial": form->,
    display,
  },
  _type == "pageBuilderForm" => {
    "rows": form->rows[] {
      fields[] {
        ...,
        options[] {
          ...,
          "label": title
        }
      }
    },
    "buttons": form->buttons,
    "name": form->name,
    "unique_name": form->unique_name,
    "actionUrl": form->action->slug.current
  },
  _type == 'pageBuilderBlogs' => {
    ...,
    display,
    "category": category->title,
    limit
  }
`
