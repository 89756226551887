export default async <T>({ query, params }: { query: any; params?: any }) => {
  const mainStore = useMainStore()

  // use preview client if preview is active
  const sanityClient = mainStore.previewIsActive
    ? {
        client: "preview",
        server: false,
        initialCache: false
      }
    : undefined

  const sanity = useSanity(sanityClient?.client)
  const { data, error, refresh } = await useSanityQuery<T>(query, params, sanityClient)

  if (mainStore.previewIsActive) {
    const observable = sanity.client?.listen(query, params)

    observable.subscribe((event: any) => {
      console.log("event result", event.result)
      data.value = event.result
    })
  }

  return { data, error, refresh }
}
