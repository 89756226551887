import payload_plugin_iSPKBl3PAL from "/opt/build/repo/www/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_BBPz3bSrU4 from "/opt/build/repo/www/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.8_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_l_a35dh4awbe3o6ex64kq27orec4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_uaNgQbEHcl from "/opt/build/repo/www/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.8_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_l_a35dh4awbe3o6ex64kq27orec4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_i2PEKN6Ymy from "/opt/build/repo/www/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.8_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_l_a35dh4awbe3o6ex64kq27orec4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_2poYRohSm9 from "/opt/build/repo/www/node_modules/.pnpm/nuxt-site-config@3.1.1_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_IMewQXkTg1 from "/opt/build/repo/www/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.8_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_l_a35dh4awbe3o6ex64kq27orec4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Hs0AYHvuPg from "/opt/build/repo/www/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.8_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_l_a35dh4awbe3o6ex64kq27orec4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_EHOb9neOuW from "/opt/build/repo/www/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.8_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_l_a35dh4awbe3o6ex64kq27orec4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_6sGA9vo4TY from "/opt/build/repo/www/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.8_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_l_a35dh4awbe3o6ex64kq27orec4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_3AQhHa0q82 from "/opt/build/repo/www/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_zlvi6dcIsi from "/opt/build/repo/www/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_aXliLkQlw2 from "/opt/build/repo/www/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.8_db0@0.2.4_eslint@8.57.1_ioredis@5.5.0_l_a35dh4awbe3o6ex64kq27orec4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_0Pde6tZTKq from "/opt/build/repo/www/node_modules/.pnpm/nuxt-aos@1.2.5_magicast@0.3.5_vite@6.2.0_@types+node@22.13.8_jiti@2.4.2_lightningcss@1.29.1_terser@5.39.0_yaml@2.7.0_/node_modules/nuxt-aos/dist/runtime/plugin.js";
import plugin_wGWrVTTakE from "/opt/build/repo/www/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node_bqshkpzeud66yma5w4g2fjrvl4/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_client_ZceLUKbQ7P from "/opt/build/repo/www/node_modules/.pnpm/@nuxtjs+web-vitals@0.2.7_magicast@0.3.5/node_modules/@nuxtjs/web-vitals/dist/runtime/plugin.client.mjs";
import colors_9HCAkIa7XD from "/opt/build/repo/www/node_modules/.pnpm/@nuxt+ui@3.0.0-beta.2_@babel+parser@7.26.9_change-case@5.4.4_db0@0.2.4_embla-carousel@8.5.2_i_xtwg4z2nsr6j4ho3bpi5lwimyy/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_uT1y72uPf5 from "/opt/build/repo/www/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_vite@6.2.0_@types+node@22.13.8_jiti@2.4.2_lightningcss@1.29._s3gyexsybmgl76jtpicahvdxnm/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import sanity_img_url_nCYk8coreD from "/opt/build/repo/www/app/plugins/sanity-img-url.js";
import sanity_Qg7snf2WOB from "/opt/build/repo/www/app/plugins/sanity.js";
export default [
  payload_plugin_iSPKBl3PAL,
  revive_payload_client_BBPz3bSrU4,
  unhead_uaNgQbEHcl,
  router_i2PEKN6Ymy,
  _0_siteConfig_2poYRohSm9,
  payload_client_IMewQXkTg1,
  navigation_repaint_client_Hs0AYHvuPg,
  check_outdated_build_client_EHOb9neOuW,
  chunk_reload_client_6sGA9vo4TY,
  plugin_vue3_3AQhHa0q82,
  components_plugin_zlvi6dcIsi,
  prefetch_client_aXliLkQlw2,
  plugin_0Pde6tZTKq,
  plugin_wGWrVTTakE,
  plugin_client_ZceLUKbQ7P,
  colors_9HCAkIa7XD,
  plugin_uT1y72uPf5,
  sanity_img_url_nCYk8coreD,
  sanity_Qg7snf2WOB
]